<template>
  <v-row>
    <v-col class="mb-4">
      <h1 class="display-1 font-weight-bold mx-1 mb-3">Account</h1>
      <p>General account info</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
  },
  async created () {
  },
  async mounted () {
  },
  methods: {
  },
  data: function () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
